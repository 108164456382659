import { DateTime } from "luxon";

/**
 * Sort a given minute into a certain 15 minute range,
 * returning a number for versatile conditional rendering
 *
 * @param {number} n
 * @returns {number}
 */
export const minuteRange = (n) => {
  if (n >= 0 && n < 15) {
    return 1;
  } else if (n >= 15 && n < 30) {
    return 2;
  } else if (n >= 30 && n < 45) {
    return 3;
  } else if (n >= 45) {
    return 4;
  }
};

/**
 * Give arrays of timeWindows and reserved showings as well as the required notice,
 * and the full showing availability for the next three days is returned.
 * @param {Array} timeWindows
 * @param {Array} reservedShowings
 * @param {number} notice
 * @returns {object} the label for subtype from LISTING_SUBTYPES
 */
export const getAllTimes = (
  timeWindows = [
    [480, 1200], // Monday
    [480, 1200], // Tuesday
    [480, 1200], // Wednesday
    [480, 1200], // Thursday
    [480, 1200], // Friday
    [480, 1200], // Saturday
    [480, 1200], // Sunday
  ],
  notice = 30,
  showingDuration = 60,
  reservedShowings = null
) => {
  const incrementSize = 15;

  // Luxon DateTime numbering system for weekdays is off from our definition by one.
  const now = DateTime.local();
  const today = now.weekday - 1;
  const tomorrow = (today + 1) % 7;
  const nextDay = (today + 2) % 7;
  const durationInIncrements = showingDuration / incrementSize;

  /*
   * If the starting time for today's showing window has already passed, determine if the window is still open.
   * If it is, use the next remaining timeslot.  Else, close the window.
   */
  let nowMinutes = now.hour * 60 + now.minute;
  if (
    Array.isArray(timeWindows[today]) &&
    timeWindows[today].length &&
    nowMinutes >= timeWindows[today][0]
  ) {
    nowMinutes = nowMinutes - (nowMinutes % incrementSize); // Round nowMinutes to the floor value.
    /*
     * Adding the notice and the increment to the floored value
     * ensures that the notice is fully accounted for
     */
    nowMinutes += notice + incrementSize;
    if (nowMinutes >= timeWindows[today][1]) {
      timeWindows[today] = [];
    } else {
      timeWindows[today][0] = nowMinutes;
    }
  }

  /*
   * From the reserved showings, create a dictionary keyed by the days of the week, which list the
   * start and end times of existing showings.
   */
  const timesReserved = {};
  if (reservedShowings) {
    reservedShowings.map((showing) => {
      const dateTimeStart = DateTime.fromJSDate(showing.start);
      const dateTimeEnd = DateTime.fromJSDate(showing.end);
      const day = dateTimeStart.weekday;
      const timeStart = dateTimeStart.hour * 60 + dateTimeStart.minute;
      const timeEnd = dateTimeEnd.hour * 60 + dateTimeEnd.minute;
      if (timesReserved[day]) {
        timesReserved[day].push({
          timeReservedStart: timeStart,
          timeReservedEnd: timeEnd,
        });
      }
    });
  }
  // For each day of the week...
  const availableTimes = timeWindows.map((timeWindow, day) => {
    // Timeslots for this day
    const timeslots = {
      minuteFormat: [],
    };

    // If there is no showing window for this day, timeslots will return as it is set by default.
    if (Array.isArray(timeWindow) && timeWindow.length) {
      // Populate timeslots.minuteFormat with all timeslots in this day's timeWindow.
      for (let i = timeWindow[0]; i <= timeWindow[1]; i += incrementSize) {
        timeslots.minuteFormat.push(i);
      }

      // Remove timeslots that overlap with the reserved times.
      if (Array.isArray(timesReserved[day] && timesReserved[day].length)) {
        timesReserved[day].forEach((reservation) => {
          const i = timeslots.minuteFormat.findIndex(reservation.timeStart);
          const reservationDurationInIncrements = Math.ceil(
            (reservation.timeStart - reservation.timeEnd) / incrementSize
          );
          /*
           * Remove timeslots that would not end before the scheduled showing starts
           * in addition to timeslots that start during the scheduled showing.
           */
          timeslots.minuteFormat.splice(
            i - durationInIncrements + 1, // Starting index
            durationInIncrements + reservationDurationInIncrements // # of entries to remove
          );
        });
      }

      // Using the remaining available timeslots, build out the hour-keyed properties.
      timeslots.minuteFormat.forEach((timeslot) => {
        const hour = Math.floor(timeslot / 60);
        const minute = timeslot % 60;
        if (!Array.isArray(timeslots[hour])) {
          timeslots[hour] = [];
        }
        timeslots[hour].push(minute);
      });
    }
    return timeslots;
  });

  // Legacy compatibility properties.
  availableTimes.today = availableTimes[today];
  availableTimes.tomorrow = availableTimes[tomorrow];
  availableTimes.nextDay = availableTimes[nextDay];

  return availableTimes;
};

/**
 * Give an object with available times, and an array of all the hours (keys) will be returned.
 * @param {object} dayTimes
 * @returns {Array} The hours available for the day
 */
export const getHours = (dayTimes) => {
  const keys = Object.keys(dayTimes);
  return keys.reduce((accumulator, currentValue) => {
    if (parseInt(currentValue)) {
      accumulator.push(parseInt(currentValue));
    }
    return accumulator;
  }, []);
};

/**
 * Give an array with minute values of all available times, as well as the minimum time cutoff,
 * and the next available time (in minutes) will be returned
 * @param {Array} times
 * @param {number} minimum
 * @returns {number} The next available time
 */
export const getNextAvailable = (times, minimum) => {
  const availableTimes = times.filter((time) => time >= minimum);
  return availableTimes[0];
};

// returns a string of the number of minutes in a given showing
export const getTimeAllocated = (startTime, endTime) => {
  const timeAllocated =
    Math.abs(endTime.valueOf() - startTime.valueOf()) / 1000 / 60;
  let timeAllocatedString;

  switch (timeAllocated) {
    case 30:
      timeAllocatedString = "30min";
      break;
    case 60:
      timeAllocatedString = "1hr";
      break;
    case 90:
      timeAllocatedString = "1hr and 30min";
      break;
    case 120:
      timeAllocatedString = "2hrs";
      break;
    default:
      timeAllocatedString = "n/a";
      break;
  }

  return timeAllocatedString;
};

// returns a formatted string to display how long ago an item was created
// just now, 15 min, 30min, 45min, 1hr-23hr, 1 day-x days ago
export const minutesToTimeDisplay = (created, format = "") => {
  let message;
  const now = Date.now();
  const timeInMillis = new Date(created).getTime();
  const createdAtSeconds = (now - timeInMillis) / 1000;
  const minutesSinceCreation = createdAtSeconds / 60;
  const hoursSinceCreation = minutesSinceCreation / 60;
  const daysSinceCreation = hoursSinceCreation / 24;

  if (createdAtSeconds < 1) {
    message = "Just Now";
  } else if (createdAtSeconds < 60 && createdAtSeconds > 1) {
    message = `${createdAtSeconds.toFixed(0)}s`;
  } else if (minutesSinceCreation >= 1 && minutesSinceCreation < 60) {
    message = `${minutesSinceCreation.toFixed(0)}m`;
  } else if (hoursSinceCreation >= 1 && hoursSinceCreation < 24) {
    message = `${hoursSinceCreation.toFixed(0)}h`;
  } else if (daysSinceCreation >= 1 && daysSinceCreation < 30) {
    message = `${daysSinceCreation.toFixed(0)}d`;
  } else {
    message = DateTime.fromJSDate(created).toFormat(format || "d/M/y");
  }

  return message;
};

// returns the th, nd, st, rd for a date
// thanks stack overflow
export const getOrdinalNum = (n) =>
  n +
  (n > 0
    ? ["th", "st", "nd", "rd"][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10]
    : "");
